// import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import { Link } from "../../UI/Common"

import { linkedBoxCls, boxCls, linkCls, imgCls, labelCls } from "./Box.module.scss"

const Box = ({ box }) => {
  if (!box) {
    console.warn("No box data.")
    return null
  }

  const { label, image, link } = box

  return (
    <>
      {link ? (
        <Link className={linkCls} link={link}>
          <div className={linkedBoxCls}>
            {image && (
              <GatsbyImage
                className={imgCls}
                style={{ position: "absolute" }}
                image={getImage(image.localFile)}
                alt={image.altText}
              />
            )}
            {label && <div className={labelCls}>{label}</div>}
          </div>
        </Link>
      ) : (
        <div className={boxCls}>
          {image && (
            <GatsbyImage
              className={imgCls}
              style={{ position: "absolute" }}
              image={getImage(image.localFile)}
              alt={image.altText}
            />
          )}
          {label && <div className={labelCls}>{label}</div>}
        </div>
      )}
    </>
  )
}

export default Box
