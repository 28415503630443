import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Section, SectionHeading, TextBlock } from "../../UI/Common"

import Box from "./Box"
import {
  sectionCls,
  boxContainerCls,
} from "./ImageBoxesBlock.module.scss"

const ImageBoxesBlock = ({ sectionHeading, text, boxes, config }) => {
  const { padding } = config || {}

  return (
    <Section className={sectionCls} padding={padding}>
      <div className="w-100">
        {sectionHeading && (
          <SectionHeading alignment="center" text={sectionHeading} />
        )}
        {text && <TextBlock text={text} alignment="center" mode="muted" />}
        <div className={boxContainerCls}>
          {boxes && boxes.map(box => <Box key={uuidv4()} box={box} />)}
        </div>
      </div>
    </Section>
  )
}

export default ImageBoxesBlock
