import { graphql } from "gatsby"
import React from "react"

import ImageBoxesBlockComponent from "./ImageBoxesBlock"

export const fragment = graphql`
  fragment ImageBoxesBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_ImageBoxesBlock {
    sectionHeading
    text
    boxes {
      label
      image {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 500
              placeholder: NONE
            )
          }
        }
      }
      link {
        title
        url
        target
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFImageBoxesBlock = ({ sectionHeading, text, boxes, config }) => (
  <ImageBoxesBlockComponent
    sectionHeading={sectionHeading}
    text={text}
    boxes={boxes}
    config={config}
  />
)
