// extracted by mini-css-extract-plugin
export var bgGrey100 = "Box-module--bg-grey-100--602f9";
export var bgGrey150 = "Box-module--bg-grey-150--7b1b8";
export var bgGrey200 = "Box-module--bg-grey-200--7731d";
export var bgGrey300 = "Box-module--bg-grey-300--26bdf";
export var bgGrey400 = "Box-module--bg-grey-400--fd611";
export var bgGrey500 = "Box-module--bg-grey-500--67f30";
export var bgGrey600 = "Box-module--bg-grey-600--89126";
export var bgGrey700 = "Box-module--bg-grey-700--842db";
export var bgGrey800 = "Box-module--bg-grey-800--42cec";
export var bgGrey900 = "Box-module--bg-grey-900--b27ce";
export var boxCls = "Box-module--boxCls--cd2ae";
export var imgCls = "Box-module--imgCls--81fa3";
export var labelCls = "Box-module--labelCls--3487f";
export var linkCls = "Box-module--linkCls--ab601";
export var linkedBoxCls = "Box-module--linkedBoxCls--f6a38";
export var textGrey100 = "Box-module--text-grey-100--ad801";
export var textGrey150 = "Box-module--text-grey-150--79ca4";
export var textGrey200 = "Box-module--text-grey-200--7c36d";
export var textGrey300 = "Box-module--text-grey-300--75750";
export var textGrey400 = "Box-module--text-grey-400--3c465";
export var textGrey500 = "Box-module--text-grey-500--bef40";
export var textGrey600 = "Box-module--text-grey-600--9b338";
export var textGrey700 = "Box-module--text-grey-700--611c4";
export var textGrey800 = "Box-module--text-grey-800--7dba2";
export var textGrey900 = "Box-module--text-grey-900--7cb20";