// extracted by mini-css-extract-plugin
export var bgGrey100 = "ImageBoxesBlock-module--bg-grey-100--2b223";
export var bgGrey150 = "ImageBoxesBlock-module--bg-grey-150--53ba2";
export var bgGrey200 = "ImageBoxesBlock-module--bg-grey-200--c5139";
export var bgGrey300 = "ImageBoxesBlock-module--bg-grey-300--7176b";
export var bgGrey400 = "ImageBoxesBlock-module--bg-grey-400--192a1";
export var bgGrey500 = "ImageBoxesBlock-module--bg-grey-500--3ff3f";
export var bgGrey600 = "ImageBoxesBlock-module--bg-grey-600--16761";
export var bgGrey700 = "ImageBoxesBlock-module--bg-grey-700--e28eb";
export var bgGrey800 = "ImageBoxesBlock-module--bg-grey-800--b0c6c";
export var bgGrey900 = "ImageBoxesBlock-module--bg-grey-900--3628b";
export var boxContainerCls = "ImageBoxesBlock-module--boxContainerCls--0e05a";
export var sectionCls = "ImageBoxesBlock-module--sectionCls--41b34";
export var textCls = "ImageBoxesBlock-module--textCls--76a61";
export var textGrey100 = "ImageBoxesBlock-module--text-grey-100--f3b63";
export var textGrey150 = "ImageBoxesBlock-module--text-grey-150--a9dec";
export var textGrey200 = "ImageBoxesBlock-module--text-grey-200--6f147";
export var textGrey300 = "ImageBoxesBlock-module--text-grey-300--59136";
export var textGrey400 = "ImageBoxesBlock-module--text-grey-400--71e35";
export var textGrey500 = "ImageBoxesBlock-module--text-grey-500--d7be7";
export var textGrey600 = "ImageBoxesBlock-module--text-grey-600--b9732";
export var textGrey700 = "ImageBoxesBlock-module--text-grey-700--d0b87";
export var textGrey800 = "ImageBoxesBlock-module--text-grey-800--dacc9";
export var textGrey900 = "ImageBoxesBlock-module--text-grey-900--f2708";